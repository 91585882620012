<template>
  <div>
    <b-form-group
      label="Título"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-title"
        v-model="$v.question_option.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-description"
        v-model="$v.question_option.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Puntaje"
      label-for="input-score"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-score"
        type="number"
        v-model="$v.question_option.score.$model"
        :state="validateState('score')"
        aria-describedby="input-score-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Argumentar"
      label-for="input-argue"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-checkbox
        id="input-argue"
        v-model="$v.question_option.to_argue.$model"
        :state="validateState('to_argue')"
        aria-describedby="input-argue-feedback"
        size="sm"
      ></b-form-checkbox>
      <b-form-invalid-feedback id="input-argue-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div class="col" style="text-align: left">
        <b-button
          v-if="show_delete_button && !isNaN(question_option.id)"
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "QuestionOptionForm",
  mixins: [validationMixin],
  props: {
    QuestionOption: {
      type: Object,
    },
    survey_question_id: {
      type: Number,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      question_option: {
        id: this.QuestionOption ? this.QuestionOption.id : generateUniqueId(),
        title: this.QuestionOption ? this.QuestionOption.title : "",
        description: this.QuestionOption ? this.QuestionOption.description : "",
        survey_question: this.QuestionOption
          ? this.QuestionOption.survey_question
          : this.survey_question_id,
        score: this.QuestionOption ? this.QuestionOption.score : null,
        to_argue: this.QuestionOption ? this.QuestionOption.to_argue : false,
        argumentation: this.QuestionOption
          ? this.QuestionOption.argumentation
          : null,
      },
    };
  },
  validations() {
    return {
      question_option: {
        title: { required },
        description: {},
        score: { required },
        to_argue: {},
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.question_option[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.question_option.$touch();
      if (this.$v.question_option.$anyError) {
        return;
      }
      if (isNaN(this.question_option.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/survey-option/", this.question_option)
        .then((response) => {
          this.question_option = response;
          this.$emit("created", response);
          toast("Opción creada.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/survey-option/${this.question_option.id}/`,
          this.question_option
        )
        .then((response) => {
          this.question_option = response;
          this.$emit("updated", response);
          toast("Opción actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Opción?",
        description: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/survey-option/${this.question_option.id}/`)
        .then(() => {
          this.$emit("deleted", this.question_option);
          toast("Opción eliminada.");
        });
    },
  },
};
</script>

<style>
</style>